import React from "react";
import "./CompanyContacts.css";
import { convertPhoneNumberLinks } from "../common/utilities/utilities";

const CompanyContacts = (props) => {
  return (
    <div>
      {props.website && (
        <div>
          <a href={props.website} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-globe" style={{ marginRight: "0.5rem" }}></i>
            {props.website.split("//").slice(-1)[0]}
          </a>
        </div>
      )}
      {props.email && (
        <div>
          <a href={`mailto: ${props.email}`}>
            <i
              className="fas fa-envelope"
              style={{ marginRight: "0.5rem" }}
            ></i>
            {props.email}
          </a>
        </div>
      )}
      {props.phone_numbers &&
      props.phone_numbers != null &&
      Array.isArray(props.phone_numbers) ? (
        <div>
          {props.phone_numbers.map((phone_number, index) => (
            <a
              key={index}
              href={`tel: ${phone_number}`}
              style={{ display: "block" }}
            >
              <i
                className="fas fa-phone fa-xs"
                style={{ marginRight: "0.5rem" }}
              ></i>
              {phone_number}
            </a>
          ))}
        </div>
      ) : (
        <div>
          {convertPhoneNumberLinks(
            props.phone_numbers ? props.phone_numbers : ""
          )}
        </div>
      )}
      <div className="socials">
        {props.facebook && (
          <a href={props.facebook} target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-square-facebook fa-2x"></i>
          </a>
        )}
        {props.viber && (
          <a href={`viber://chat?number=${props.viber.slice(1)}`}>
            <i className="fab fa-viber fa-2x"></i>
          </a>
        )}
        {props.line && (
          <a href={`line://ti/p/${props.line}`}>
            <i className="fab fa-line fa-2x"></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default CompanyContacts;
