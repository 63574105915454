import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";

import { getStates } from "../../common/redux/actions/StatesActions";
import { reactSelectDropdownStyle } from "../../common/styles/dropdowns";

const StateSelect = ({
  initialValue,
  placeholder,
  country,
  dependant,
  onChange,
  isSearchable,
  isMulti,
  isClearable,
}) => {
  const dispatch = useDispatch();
  const { items, fetched } = useSelector((state) => state.StatesState);

  const [selectedState, setSelectedState] = useState(initialValue);

  useEffect(() => {
    if (!fetched) {
      dispatch(getStates());
    }
  }, [dispatch, fetched]);

  const stateOptions = useMemo(() => {
    return Object.values(items || {})
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ state, name, country }) => ({
        value: state,
        label: name,
        country,
      }));
  }, [items]);

  const stateFilteredOptions = useMemo(() => {
    if (dependant && country) {
      return stateOptions.filter((option) => option.country === country);
    }
    return stateOptions;
  }, [dependant, country, stateOptions]);

  useEffect(() => {
    setSelectedState(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const currentState = stateOptions.find(
      (option) => option.value === selectedState
    );

    if (currentState && currentState.country !== country) {
      setSelectedState(null);
      if (onChange) onChange(null);
    }
  }, [country, stateOptions, selectedState, onChange]);

  const handleStateChange = (selectedOption) => {
    const value = selectedOption?.value || null;
    setSelectedState(value);
    if (onChange) onChange(value);
  };

  return (
    <Select
      name="state"
      options={stateFilteredOptions}
      value={
        stateOptions.find((option) => option.value === selectedState) || null
      }
      placeholder={placeholder}
      onChange={handleStateChange}
      styles={reactSelectDropdownStyle}
      isSearchable={isSearchable}
      isMulti={isMulti}
      isClearable={isClearable}
    />
  );
};

StateSelect.propTypes = {
  initialValue: PropTypes.any,
  placeholder: PropTypes.string,
  country: PropTypes.any,
  dependant: PropTypes.bool,
  onChange: PropTypes.func,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
};

export default StateSelect;
