import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import axios from "axios";
import { Container, Button } from "react-bootstrap";
import { setLanguage } from "redux-i18n";
import ReactGA from "react-ga4";
import { ToastContainer } from "react-toastify";

import HomeView from "./containers/HomeView";
import RegistrationView from "./containers/RegistrationView";
import LoginView from "./containers/LoginView";
import LoginRequiredView from "./containers/LoginRequiredView";
import LikesView from "./containers/LikesView";
import AppliedView from "./containers/AppliedView";
import ProfileView from "./containers/ProfileView";
import JobsViewV2 from "./containers/Jobs/JobsViewV2";
import JobApplicationsView from "./containers/JobApplications/JobApplicationsView";
import JobsDetailedView from "./containers/Jobs/JobsDetailedView";
import ServiceProvidersListView from "./containers/ServiceProviders/ServiceProvidersListView";
import RecruitmentAgenciesListView from "./containers/RecruitmentAgencies/RecruitmentAgenciesListView";
import EmployersListView from "./containers/Employers/EmployersListView";
import GuidesView from "./containers/Guides/GuidesView";
import NewsView from "./containers/News/NewsView";
import NotificationsView from "./containers/Notifications";
import PollsListViewV2 from "./containers/Polls/PollsListViewV2";
import ScreeningView from "./containers/Jobs/Screening/ScreeningView";
import SurveysDetailedView from "./containers/Surveys/SurveysDetailedView";
import SurveysListView from "./containers/Surveys/SurveysListView";

import GuidesDetailedView from "./containers/Guides/GuidesDetailedView";
import NewsDetailedView from "./containers/News/NewsDetailedView";
import ServiceProvidersDetailedView from "./containers/ServiceProviders/ServiceProvidersDetailedView";
import RecruitmentAgenciesDetailedView from "./containers/RecruitmentAgencies/RecruitmentAgenciesDetailedView";
import EmployersDetailedViewV2 from "./containers/Employers/EmployersDetailedViewV2";
import ChooseLanguageView from "./containers/ChooseLanguageView";
import ProblemsView from "./containers/Problems/ProblemsView";
import DetailedProblemView from "./containers/Problems/DetailedProblemView";
import JobseekersResumeView from "./containers/Jobseekers/JobseekersResumeView";
import JobseekersEditResumeView from "./containers/Jobseekers/JobseekersEditResumeView";
import JobseekersDetailedResumeView from "./containers/Jobseekers/JobseekersDetailedResumeView";
import PrivacyPolicyView from "./containers/StaticPages/PrivacyPolicyView";
import MemberAgreementView from "./containers/StaticPages/MemberAgreementView";

import NotFoundView from "./containers/NotFoundView";

import {
  authAutoLogin,
  clearAuthLocalStorage,
} from "./common/redux/actions/AuthenticationActions";
import { fetchJobFromNotification } from "./common/redux/actions/JobsActions";
import { addNewsFromNotification } from "./common/redux/actions/NewsActions";
import { getLanguages } from "./common/redux/actions/LanguagesActions";
import { getPollsQuestions } from "./common/redux/actions/PollsActions";
import { getSurveys } from "./common/redux/actions/SurveysActions";
import { showPushNotificationToast } from "./common/redux/actions/PushNotificationActions";
import {
  receiveNotification,
  notifyJobApplication,
} from "./common/redux/actions/ProfileActions";
import { getCountries } from "./common/redux/actions/CountriesActions";

import { updateMomentLocale } from "./common/i18n/utilities";
import slackLogger from "./common/utilities/slackLogger";
import history from "./history";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import MarketPlaceHomeView from "./containers/MarketPlace/MarketPlaceHomeView";
import FilteredJobsByEmployerView from "./containers/Jobs/FilteredJobsByEmployerView";
import RecruiterRosterView from "./containers/RecruitmentAgencies/RecruiterRosterView";
import RecruiterRosterDetailedView from "./containers/RecruitmentAgencies/RecruiterRosterDetailedView";
import PostListView from "./containers/Forum/PostListView";
import PostDetailedView from "./containers/Forum/PostDetailedView";
import "react-lazy-load-image-component/src/effects/blur.css";
import ForgotPasswordView from "./containers/PasswordReset/ForgotPasswordView";
import ResetPasswordForm from "./containers/PasswordReset/ResetPasswordForm";
import EmployerRosterView from "./containers/Employers/EmployerRosterView";
import EmployerRosterDetailedView from "./containers/Employers/EmployerRosterDetailedView";

const PrivateRoute = ({ component: Component, isAuthenticated }) => (
  <Route
    render={(props) =>
      isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={`/login-required?next=${props.history.location.pathname}`}
        />
      )
    }
  />
);

const GuestRoute = ({ component: Component, isAuthenticated }) => (
  <Route
    render={(props) =>
      isAuthenticated === false ? (
        <Component {...props} />
      ) : (
        <Redirect to={`/${props.history.location.search}`} />
      )
    }
  />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    localStorage.setItem("landedUrl", window.location.pathname);
    this.state = {
      PushNotificationToast: {
        show: false,
        data: {},
      },
      hasError: false,
    };
    this.onFirebaseMessage = this.onFirebaseMessage.bind(this);
  }

  UNSAFE_componentWillMount() {
    const language = localStorage.getItem("language");
    if (language) {
      this.props.setLanguage(language);
      updateMomentLocale(language);
    }
    this.props
      .authAutoLogin()
      .catch((e) => {
        if (e.response && [400, 401, 404].includes(e.response.status)) {
          clearAuthLocalStorage();
          window.location.reload();
        }
      })
      .finally(() => {
        this.props.getLanguages();
        this.props.getPollsQuestions();
        this.props.getSurveys();
        this.props.fetchCountries();
      });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Log the error to an error reporting service
    if (process.env.REACT_APP_ENABLE_SLACK === "true") {
      slackLogger.sendReactCrashed(error);
    }
  }

  async updateNotificationRelatedObject(notificationType, firebaseData) {
    if (
      [
        "APPLICATION_VIEWED",
        "APPLICATION_DOCUMENTS_REQUESTED",
        "APPLICATION_DOWNLOADED",
        "APPLICATION_SHORTLISTED",
        "APPLICATION_OFFERED",
        "APPLICATION_REJECTED_BY_BUSINESS",
      ].includes(notificationType)
    ) {
      const applicationID = JSON.parse(firebaseData.application);

      // can't pass whole application from the server, Firebase message has a limit,
      // so only ID is passed, then application is fetched on the client
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/job-applications/${applicationID}/`
        )
        .then((res) => this.props.notifyJobApplication(res.data));
    } else if (
      notificationType == "CONTENT_ADDED_NEWS" &&
      this.props.news.fetched
    ) {
      const news = JSON.parse(firebaseData.news);
      this.props.addNewsFromNotification(news);
    } else if (
      notificationType == "NEW_JOB_POSTED" &&
      this.props.jobs.fetched
    ) {
      this.props.fetchJobFromNotification(firebaseData.job);
    }
  }

  onFirebaseMessage(message) {
    return setTimeout(() => {
      if (message.data["firebase-messaging-msg-data"].notification) {
        const firebaseData = message.data["firebase-messaging-msg-data"];

        // get notification object and add to redux
        const notificationData = firebaseData.data.notification_data;
        if (notificationData) {
          this.props.receiveNotification(notificationData);

          this.updateNotificationRelatedObject(
            notificationData.type,
            firebaseData.data
          );
        }
        // display notification toast
        this.props.showPushNotificationToast({
          ...firebaseData.data,
          ...firebaseData.notification,
        });
      }
    }, 1000);
  }

  componentDidMount() {
    console.log("Play Store URL: ", process.env.REACT_APP_ANDROID_URL);
    // initialize Google analytics
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID);

    // add firebase notifications listener
    navigator.serviceWorker &&
      navigator.serviceWorker.addEventListener(
        "message",
        this.onFirebaseMessage
      );
  }

  componentWillUnmount() {
    navigator.serviceWorker &&
      navigator.serviceWorker.removeEventListener(
        "message",
        this.onFirebaseMessage
      );
  }

  render() {
    const preferredLanguage = localStorage.getItem("language");
    const refreshLink = (
      <div>
        <br />
        <Button
          variant="link"
          className="gd-button button-modified"
          onClick={() => window.location.reload()}
        >
          {this.context.t("refresh the page")}
        </Button>
      </div>
    );

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container
          style={{
            textAlign: "center",
            wordBreak: this.props.lang === "en" && "break-word",
          }}
        >
          <div className="centeredBox" style={{ flexDirection: "column" }}>
            <img
              src={"/static/media/wentWrong.png"}
              alt=""
              style={{ height: 150, display: "block" }}
            />
            <p className="mt-3">
              {this.context.t(
                "Ooops, something went wrong... Please try to {refresh}",
                { refresh: refreshLink }
              )}
            </p>
          </div>
        </Container>
      );
    }

    return (
      <Container
        fluid={true}
        style={{ padding: 0, textAlign: "center", height: "100%" }}
      >
        {this.props.loading ? (
          <div style={{ display: "flex", height: "100%" }}>
            <img
              src={"/static/media/spinner.png"}
              alt=""
              className="LoadingSpinner"
            />
          </div>
        ) : preferredLanguage ? (
          <Switch>
            <Route exact path="/" component={HomeView} />
            <Route exact path="/jobs" component={JobsViewV2} />
            <Route
              exact
              path="/jobs/employer/:id"
              component={FilteredJobsByEmployerView}
            />
            <Route exact path="/jobs/:id" component={JobsDetailedView} />
            <Route
              exact
              path="/jobs/:jobId/screening"
              component={ScreeningView}
            />
            <Route
              exact
              path="/job-applications"
              component={JobApplicationsView}
            />
            <Route
              exact
              path="/services"
              component={ServiceProvidersListView}
            />
            <Route
              exact
              path="/services/:id"
              component={ServiceProvidersDetailedView}
            />
            <Route exact path="/guides" component={GuidesView} />
            <Route exact path="/guides/:id" component={GuidesDetailedView} />
            <Route exact path="/news" component={NewsView} />
            <Route exact path="/news/:id" component={NewsDetailedView} />
            <Route exact path="/marketplace" component={MarketPlaceHomeView} />
            <Route
              exact
              path="/recruitment-agencies"
              component={RecruitmentAgenciesListView}
            />
            <Route
              exact
              path="/recruitment-agencies/:id"
              component={RecruitmentAgenciesDetailedView}
            />
            <Route
              exact
              path="/recruiter-roster"
              component={RecruiterRosterView}
            />
            <Route
              exact
              path="/recruiter-roster/:recruiter_id"
              component={RecruiterRosterDetailedView}
            />
            <Route
              exact
              path="/employer-roster"
              component={EmployerRosterView}
            />
            <Route
              exact
              path="/employer-roster/:employer_id"
              component={EmployerRosterDetailedView}
            />
            <Route exact path="/employers" component={EmployersListView} />
            <Route
              exact
              path="/employers/:id"
              component={EmployersDetailedViewV2}
            />
            <Route exact path="/polls" component={PollsListViewV2} />
            <Route
              exact
              path="/change-language"
              component={ChooseLanguageView}
            />
            <Route
              exact
              path="/member-agreement"
              component={MemberAgreementView}
            />
            <Route exact path="/problems" component={ProblemsView} />
            <Route exact path="/problems/:id" component={DetailedProblemView} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyView} />
            <GuestRoute
              path="/registration"
              component={RegistrationView}
              isAuthenticated={this.props.isAuthenticated}
            />
            <GuestRoute
              exact
              path="/login"
              component={LoginView}
              isAuthenticated={this.props.isAuthenticated}
            />
            <GuestRoute
              exact
              path="/login-required"
              component={LoginRequiredView}
              isAuthenticated={this.props.isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/notifications"
              component={NotificationsView}
              isAuthenticated={this.props.isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/applied"
              component={AppliedView}
              isAuthenticated={this.props.isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/like"
              component={LikesView}
              isAuthenticated={this.props.isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/resume"
              component={JobseekersResumeView}
              isAuthenticated={this.props.isAuthenticated}
              history={history}
            />
            <PrivateRoute
              exact
              path="/resume-edit"
              component={JobseekersEditResumeView}
              isAuthenticated={this.props.isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/resume-view"
              component={JobseekersDetailedResumeView}
              isAuthenticated={this.props.isAuthenticated}
            />
            <Route exact path="/surveys" component={SurveysListView} />
            <Route
              exact
              path="/surveys/:identifier"
              component={SurveysDetailedView}
            />
            <Route
              exact
              path="/forgot-password"
              component={ForgotPasswordView}
            />
            <Route
              exact
              path="/api/v2/reset-password/*"
              component={ResetPasswordForm}
            />
            <Route exact path="/groups" component={PostListView} />
            <Route
              exact
              path="/groups/:id/:langId"
              component={PostDetailedView}
            />
            <PrivateRoute
              exact
              path="/profile"
              component={ProfileView}
              isAuthenticated={this.props.isAuthenticated}
            />
            <Route path="*" component={NotFoundView} />
          </Switch>
        ) : (
          <ChooseLanguageView />
        )}
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
        />
      </Container>
    );
  }
}

App.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.AuthenticationState.token !== null,
    loading:
      state.ProfileState.loading ||
      state.UsersState.loading ||
      state.LanguagesState.loading,
    profile: state.ProfileState.data,
    jobs: state.JobsState,
    lang: state.i18nState.lang,
    news: state.NewsState,
    countries: state.CountriesState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authAutoLogin: () => dispatch(authAutoLogin()),
    getLanguages: () => dispatch(getLanguages()),
    getPollsQuestions: () => dispatch(getPollsQuestions()),
    getSurveys: () => dispatch(getSurveys()),
    setLanguage: (language) => dispatch(setLanguage(language)),
    showPushNotificationToast: (data) =>
      dispatch(showPushNotificationToast(data)),
    receiveNotification: (notification) =>
      dispatch(receiveNotification(notification)),
    notifyJobApplication: (application) =>
      dispatch(notifyJobApplication(application)),
    fetchJobFromNotification: (jobId) =>
      dispatch(fetchJobFromNotification(jobId)),
    addNewsFromNotification: (news) => dispatch(addNewsFromNotification(news)),
    fetchCountries: () => dispatch(getCountries()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
