import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Spinner } from "react-bootstrap";

const RosterWithdrawConfirmationModal = (
  { show, handleClose, handleWithdraw, businessName, withdrawing },
  context
) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header style={{ backgroundColor: "#198474", color: "white" }}>
        <Modal.Title>
          <strong>{context.t("Confirmation!")}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {context.t(
            "Are you sure want to withdraw this application? Your application will no longer be seen in {business_name}'s list.",
            { business_name: businessName }
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button-modified"
          variant="outline-secondary"
          onClick={handleClose}
        >
          {context.t("Cancel")}
        </Button>
        <Button
          className="button-modified"
          variant="danger"
          onClick={handleWithdraw}
          disabled={withdrawing}
        >
          {withdrawing ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {context.t("Withdrawing...")}
            </>
          ) : (
            context.t("Yes, withdraw")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RosterWithdrawConfirmationModal;

RosterWithdrawConfirmationModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
