import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Badge, Container, Row, Col } from "react-bootstrap";
import ShareAppButtons from "../../components/ShareAppButtons";

export default function JobseekersMenu(props, context) {
  const language = useSelector((state) => state.i18nState.lang);
  const profile = useSelector((state) => state.ProfileState.data);
  const notifications = useSelector((state) => state.NotificationsState.items);
  const getHotlineNumber = (language) => {
    if (language === "kh") {
      return (
        <Col xs={4} className="mt-3">
          <a href={`tel: 1800010181`} className="NavigationBar-icon">
            <i className="fa-solid fa-phone-square fa-2x"></i>
            <span> {context.t("Free hotline")} </span>
          </a>
        </Col>
      );
    }

    if (language === "mm-z" || language === "mm-u") {
      return (
        <>
          <Col xs={4} className="mt-3">
            <a href={`tel: 1800010180`} className="NavigationBar-icon">
              <i className="fa-solid fa-phone-square fa-2x"></i>
              <span> {context.t("Thailand Hotline (free)")} </span>
            </a>
          </Col>
          <Col xs={4} className="mt-3">
            <a href={`tel: 09765000150`} className="NavigationBar-icon">
              <i className="fa-solid fa-phone-square fa-2x"></i>
              <span> {context.t("Yangon Hotline")} </span>
            </a>
          </Col>
        </>
      );
    }

    if (language === "ne") {
      return (
        <>
          <Col xs={4} className="mt-3">
            <a href={`tel: +977 9765415706`} className="NavigationBar-icon">
              <i className="fa-solid fa-phone-square fa-2x"></i>
              <span> {context.t("Nepal Hotline (free)")} </span>
            </a>
          </Col>
        </>
      );
    }
  };

  const unreadNotificationsCount = (notificationType) => {
    let count = 0;

    notificationType.forEach((type) => {
      if (notifications[type]) {
        count += notifications[type];
      }
    });

    return count > 9 ? "+9" : count === 0 ? "" : count;
  };

  return (
    <Container style={{ wordBreak: "break-word" }}>
      <Row className="mt-4">
        <Col xs={4}>
          <Link to="/recruitment-agencies" className="NavigationBar-icon">
            <i className="fa-solid fa-id-card fa-2x"></i>
            <span> {context.t("Recruiters")} </span>
          </Link>
        </Col>
        <Col xs={4}>
          <Link to="/employers" className="NavigationBar-icon">
            <i className="fa-solid fa-briefcase fa-2x"></i>
            <span> {context.t("Employers")} </span>
          </Link>
        </Col>
        <Col xs={4}>
          <Link to="/services" className="NavigationBar-icon">
            <i className="fa-solid fa-life-ring fa-2x"></i>
            <span> {context.t("Services")} </span>
          </Link>
        </Col>
        <Col xs={4} className="mt-3">
          <Link to="/problems" className="NavigationBar-icon">
            <i className="fa-solid fa-bullhorn fa-2x"></i>
            <span> {context.t("Share a problem")} </span>
          </Link>
        </Col>

        {getHotlineNumber(language)}

        <Col xs={4} className="mt-3">
          <Link to="/change-language" className="NavigationBar-icon">
            <i className="fa-solid fa-globe-asia fa-2x"></i>
            <span> {context.t("Language")} </span>
          </Link>
        </Col>

        <Col xs={4} className="mt-3">
          <Badge
            pill
            variant="danger"
            size="sm"
            className="navigationItemCountBadge"
          >
            {unreadNotificationsCount([
              "APPLICATION_ACCEPTED",
              "APPLICATION_DOWNLOADED",
              "APPLICATION_REJECTED",
              "APPLICATION_SHORTLISTED",
              "APPLICATION_VIEWED",
              "NEW_JOB_POSTED",
            ])}
          </Badge>
          {profile && profile.type != "IS" && (
            <Link to={"/marketplace"} className="NavigationBar-icon">
              <img
                src="/static/media/icon-marketplace.png"
                style={{ height: 30.6 }}
              />
              <span> {context.t("Marketplace")} </span>
            </Link>
          )}
        </Col>

        <Col xs={4} className="mt-3">
          <Link to="/notifications" className="NavigationBar-icon">
            {notifications &&
              (() => {
                const totalNotifications = Object.values(notifications).reduce(
                  (total, count) => total + count,
                  0
                );

                return totalNotifications > 0 ? (
                  <Badge
                    pill
                    variant="danger"
                    className="navigationItemCountBadge"
                  >
                    {totalNotifications > 9 ? "9+" : totalNotifications}
                  </Badge>
                ) : null;
              })()}
            <i className="fa-solid fa-bell fa-2x"></i>
            <span> {context.t("Notifications")}</span>
          </Link>
        </Col>
        <Col xs={4} className="mt-3">
          <ShareAppButtons title={context.t("GoldenDreams App")} />
          <span> {context.t("Share App")}</span>
        </Col>
        <Col xs={4} className="mt-3">
          <Link to="/groups" className="NavigationBar-icon">
            <i className="fa-solid fa-comments fa-2x"></i>
            <span> {context.t("Groups")}</span>
          </Link>
        </Col>

        {/* ONLY ISSARA */}
        {profile && profile.type == "IS" && (
          <Col xs={4} className="mt-3">
            <Link
              to="/surveys"
              className="NavigationBar-icon"
              style={{ color: "orange" }}
            >
              <i className="fa-solid fa-pen fa-2x"></i>
              <span> {context.t("Surveys")}</span>
            </Link>
          </Col>
        )}
      </Row>
    </Container>
  );
}

JobseekersMenu.contextTypes = {
  t: PropTypes.func.isRequired,
};
