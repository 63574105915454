import React, { useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import "./RosterApplicationModal.css";
import PropTypes from "prop-types";

const RosterApplicationModal = (props, context) => {
  const { payload, setPayload, submittingResume, submitResume, type } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setPayload({
      ...payload,
      allowed_to_view_documents: false,
    });
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        onClick={handleShow}
        style={{ marginBottom: "10px" }}
        variant="success"
        className="btn-block btn-md button-modified gd-green"
      >
        <strong>{context.t("Submit Resume")}</strong>
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-custom"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong className="purple">
              {context.t("Permission & Remark")}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>
              {type === "recruiter"
                ? context.t(
                    "Recruiters need to see your documents to confirm you are eligible for the job."
                  )
                : context.t(
                    "Employers need to see your documents to confirm you are eligible for the job."
                  )}
            </strong>
          </p>
          <Row>
            <Col xs={12}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    name="allowed_to_view_documents"
                    type="checkbox"
                    className="significant"
                    label={
                      type === "recruiter"
                        ? context.t(
                            "Tick the box to allow the recruiter to view the attached documents and all information included in your CV for recruitment purposes"
                          )
                        : context.t(
                            "Tick the box to allow the employer  to view the attached documents and all information included in your CV for recruitment purposes"
                          )
                    }
                    onChange={(event) =>
                      setPayload({
                        ...payload,
                        allowed_to_view_documents: event.target.checked,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{context.t("Remark")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="remark"
                    value={payload.remark}
                    placeholder={context.t("Enter remark")}
                    onChange={(event) =>
                      setPayload({ ...payload, remark: event.target.value })
                    }
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="mt-3"
            variant="outline-secondary button-modified"
            size="sm"
            disabled={submittingResume}
            onClick={handleClose}
          >
            {context.t("Cancel")}
          </Button>
          <Button
            variant="success"
            className="mt-3 button-modified"
            disabled={payload.allowed_to_view_documents === false}
            onClick={(e) => submitResume(e)}
            style={{ marginLeft: "3px" }}
            size="sm"
          >
            <strong>
              {submittingResume
                ? context.t("Submitting Resume")
                : context.t("Submit")}
            </strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RosterApplicationModal;

RosterApplicationModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
